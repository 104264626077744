//@author: devin
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { ServerSidePagination } from "../../../pojo/ServerSidePagination";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { GenericDropdownUtil } from "./generic.dropdown.util";
import { DropDownPaginatedModel } from "../../../pojo/DropDownPaginatedModel";

@TypeManagerDecorator("s25-generic-dropdown")
@Component({
    selector: "s25-generic-dropdown",
    template: `
        @if (this.items) {
            <s25-dropdown-paginated
                [choice]="this.choice || defaultChoice"
                [model]="this.model"
                [onSelect]="this.onSelect"
                [(chosen)]="this.chosen"
                [onLoad]="onLoad"
                [ariaLabel]="ariaLabel"
                (chosenChange)="onChosenChange($event)"
            ></s25-dropdown-paginated>
        }

        <ng-template #defaultChoice let-item="item" let-$transcludeCtrl="$transcludeCtrl" let-chosen="chosen">
            <div [class.chosen]="chosen">
                <span [innerHTML]="item.txt"></span>
                @if (item.itemDesc && !chosen) {
                    <span>&nbsp;({{ item.itemDesc }})</span>
                }
                @if (
                    $transcludeCtrl.isLoggedIn && $transcludeCtrl.model.hasFav && item.itemId && isNumeric(item.itemId)
                ) {
                    <s25-favorite [item]="item">
                        <span class="ngFloatRight ngClearBoth ngSearchSelectFavorite"></span>
                    </s25-favorite>
                }
            </div>
        </ng-template>
    `,
    styles: `
        s25-generic-dropdown .chosen {
            display: flex;
            gap: 0.5em;
        }
        s25-generic-dropdown .chosen s25-favorite {
            align-self: center;
        }
        s25-dropdown-paginated .s25-item-holder .s25-item-name {
            line-height: 2em;
        }
        .ngStarOn {
            margin-left: 0.6em;
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25GenericDropdownComponent implements OnInit, OnChanges {
    @Input() items: DropDownItem[];
    @Input() staticItems?: DropDownItem[];
    @Input() chosen: DropDownItem;
    @Input() onSelect: Function;
    @Input() autoOpen?: boolean;
    @Input() searchEnabled?: boolean;
    @Input() focusFirst?: boolean;
    @Input() alwaysOpen?: boolean;
    @Input() hideSearch?: boolean;
    @Input() apiBean?: any;
    @Input() resetSelectedOnCleanup: boolean;
    @Input() placeholder: string;
    @Input() serverSide: ServerSidePagination;
    @Input() hasFav: boolean;
    @Input() popoverClass?: string;
    @Input() choice: TemplateRef<any>;
    @Input() useChoiceForChosen?: boolean;
    @Input() onLoad?: Function;
    @Input() groupProp: string = "grp";
    @Input() ariaLabel?: string;

    @Output() chosenChange = new EventEmitter<DropDownItem>();

    constructor(private changeDetector: ChangeDetectorRef) {}

    origItems: DropDownItem[];
    model: DropDownPaginatedModel;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            this.ngOnInit();
            this.changeDetector.detectChanges();
        }
    }

    ngOnInit() {
        if (this.items && !this.items.length && this.chosen) {
            this.items.push(this.chosen); //add chosen to items list if no items present so it appears as selected
        }

        this.origItems = S25Util.deepCopy(this.items);

        //if custom filter and items, pre-filter them based on custom filter
        if (this.apiBean && this.apiBean.customFilter && this.items && this.items.length) {
            this.items = this.items.filter((item: DropDownItem) => this.apiBean.customFilter(item));
        }

        this.chosen && GenericDropdownUtil.extractItems([this.chosen]);
        this.model = {
            hasFav: S25Util.toBool(this.hasFav),
            staticItems: GenericDropdownUtil.extractItems(this.staticItems),
            items: GenericDropdownUtil.extractItems(this.items),
            itemNameProp: "txt",
            groupNameProp: this.groupProp,
            serverSide: this.serverSide,
            placeholder: this.placeholder || "Select an Item",
            emptyText: "No Items Available",
            searchEnabled: S25Util.toBool(this.searchEnabled),
            autoOpen: S25Util.toBool(this.autoOpen),
            focusFirst: S25Util.toBool(this.focusFirst),
            alwaysOpen: S25Util.toBool(this.alwaysOpen),
            hideSearch: S25Util.toBool(this.hideSearch),
            resetSelectedOnCleanup: S25Util.toBool(this.resetSelectedOnCleanup),
            popoverClass: this.popoverClass || "",
            useChoiceForChosen: this.useChoiceForChosen,
        };

        this.apiBean = this.apiBean || {};
        S25Util.extend(this.apiBean, {
            getItems: () => {
                return this.model.items || [];
            },
            getOrigItems: () => {
                return this.origItems;
            }, //return orig items to caller
            getFirstElementId: () => {
                //exposed via dropdown-paginated
                return this.model.getFirstElementId();
            },
            getHighlightedItem: () => {
                //exposed via dropdown-paginated
                return this.model.getHighlightedItem();
            },
        });
    }

    onChosenChange(data: any) {
        this.chosenChange.emit(data);
    }

    isNumeric(itemId: any) {
        return S25Util.isNumeric(itemId);
    }
}
